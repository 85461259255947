<template>
  <TitleWrapper
    :filter-option="true"
    :title="['section_student', 'guardian'].includes(activeRole) ? 'PAYABLE' : 'RECEIVABLE'"
    tooltip-title="RECEIVABLE_TOOLTIP"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiMultiSelect
          v-if="isStudentFilerDisplay"
          v-model.trim="filtersPayload.student_ids"
          title="STUDENTS"
          :options="studentList"
          reduce="id"
          label="full_name"
          class="flex-1 mb-5 sm:mb-0"
          input-color="bg-white"
          :already-selected="selectedStudents"
          :search-through-api="true"
          :filter="FILTERS_LIST.STUDENTS"
          @search="getStudents"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.title"
          class="flex-1"
          title="TITLE"
          type="text"
          placeholder="TITLE"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.TITLE"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.inserted_at_date_after"
          title="CREATED_AT_AFTER"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.START_DATE_TIME"
          :filter="FILTERS_LIST.INSERTED_DATE_GREATER_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="createdDateAfter"
        />
        <UiDatePicker
          v-model="filtersPayload.inserted_at_date_before"
          title="CREATED_AT_BEFORE"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.END_DATE_TIME"
          :filter="FILTERS_LIST.INSERTED_DATE_LESS_THAN_EQUAL"
          picker="date"
          @emitQuery="updateQuery"
          @change="createdDateBefore"
        />
        <UiSingleSelect
          v-model="filtersPayload.status"
          title="P_STATUS"
          :options="status"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="name"
          reduce="value"
          :filter="{
            option: FILTER_KEYS.EQUAL,
            key: 'status',
          }"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.status_by_due_date"
          title="DUE_DATE_STATUS"
          :options="dueDateStatus"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="name"
          reduce="value"
          :filter="{
            option: FILTER_KEYS.EQUAL,
            key: 'status_by_due_date',
          }"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.fee_amount_greater_than"
          v-model:secondary="filtersPayload.fee_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="Fee Amount"
          type="range"
          placeholder="Fee Amount"
          rules="numeric"
          :filter="FILTERS_LIST.FEE_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.paid_amount_greater_than"
          v-model:secondary="filtersPayload.paid_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="PAID_AMOUNT"
          type="range"
          placeholder="PAID_AMOUNT"
          rules="numeric"
          :filter="FILTERS_LIST.PAID_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.payable_amont_greater_than"
          v-model:secondary="filtersPayload.payable_amont_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="Payable Amount"
          type="range"
          placeholder="Payable Amount"
          rules="numeric"
          :filter="FILTERS_LIST.PAYABLE_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.fine_amount_greater_than"
          v-model:secondary="filtersPayload.fine_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="FINE_AMOUNT"
          type="range"
          placeholder="FINE_AMOUNT"
          rules="numeric"
          :filter="FILTERS_LIST.FINE_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.discount_amount_greater_than"
          v-model:secondary="filtersPayload.discount_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="DISCOUNT_AMOUNT"
          type="range"
          placeholder="DISCOUNT_AMOUNT"
          rules="numeric"
          :filter="FILTERS_LIST.DISCOUNT_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model="filtersPayload.fee_type_id"
          title="FEE_TYPE"
          :options="feeTypes"
          :class="$style.widthItems"
          class="flex-1"
          input-color="bg-white"
          reduce="id"
          :label="'title'"
          :filter="{
            option: FILTER_KEYS.EQUAL,
            key: 'fee_type_id',
          }"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.due_date_after"
          title="Due Date After"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.START_DATE_TIME"
          :filter="FILTERS_LIST.DUE_DATE_GREATER_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="dueDateAfter"
        />
        <UiDatePicker
          v-model="filtersPayload.due_date_before"
          title="Due Date Before"
          :class="$style.widthItems"
          class="flex-1"
          :date-mode="DATE_MODE.END_DATE_TIME"
          :filter="FILTERS_LIST.DUE_DATE_LESS_THAN_EQUAL"
          @emitQuery="updateQuery"
          @change="dueDateBefore"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import feeConstants from '@src/constants/fee-constants'
import { getCountFromObject } from '@utils/generalUtil'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import { buildWhereQuery } from '@/src/utils/filters'
import { mapActions, mapState } from 'vuex'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { DATE_MODE } from '@/src/constants/date-time-constants.js'
import { TENANT_ROLES } from '@/src/constants/user-roles-constants'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiSingleSelect,
    TitleWrapper,
    UiMultiSelect,
    UiDatePicker,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      DATE_MODE,
      FILTER_KEYS,
      studentList: [],
      $where: {},
      selectedStudents: [],
      FILTERS_LIST,
      status: feeConstants.receivableStatus,
      dueDateStatus: feeConstants.receivableDueStatus,
      filtersPayload: {
        student_ids: [],
        inserted_at_date_after: '',
        inserted_at_date_before: '',
        title: '',
        fee_type_id: '',
        fee_amount_greater_than: '',
        fee_amount_less_than: '',
        paid_amount_greater_than: '',
        paid_amount_less_than: '',
        payable_amont_greater_than: '',
        payable_amont_less_than: '',
        status: '',
        status_by_due_date: '',
        fine_amount_greater_than: '',
        fine_amount_less_than: '',
        due_date_after: '',
        discount_amount_greater_than: '',
        discount_amount_less_than: '',
        due_date_before: '',
        displayedColumns: [],
      },
      feeTypes: [],
      sectionStudents: [],
      filtersCount: 0,
    }
  },

  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
      sectionId: (state) => state.layout.currentSectionScope.id,
      activeRole: (state) => state.layout.activeRole,
    }),

    isStudentFilerDisplay() {
      return [
        TENANT_ROLES.SUPER_ADMIN,
        TENANT_ROLES.CAMPUS_ADMIN,
        TENANT_ROLES.SECTION_TEACHER,
        TENANT_ROLES.SECTION_ADMIN,
      ].includes(this.activeRole)
    },
  },
  watch: {
    currentCampusScope: {
      handler(value) {
        if (value) {
          this.clearFilters()
          this.getStudents()
        }
      },
    },

    currentSectionScope: {
      handler() {
        this.clearFilters()
        this.getStudents()
      },
    },
  },
  mounted() {
    this.getStudents()
    this.updateDisplayedColumnList()
    this.loadData()
  },
  methods: {
    getStudents(query) {
      if (!this.isStudentFilerDisplay) return
      let alreadySelectedStudents = []
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = res?.records
        this.studentList?.forEach((record) => {
          if (this.filtersPayload && this.filtersPayload.student_ids.length) {
            this.filtersPayload.student_ids.forEach((id) => {
              if (record.id === id) {
                alreadySelectedStudents.push(record)
              }
            })
          }
          this.selectedStudents = alreadySelectedStudents
        })
      })
    },
    loadData() {
      this.getFeeTypes().then((res) => {
        this.feeTypes = res.data.records
      })
    },
    getFiltersCount() {
      this.getStudents()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('fee', ['getFeeTypes']),
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
