<template>
  <ReceivableFilter
    class="mb-5"
    :csv-filter-options="displayedFilterOptions"
    :already-selected-displayed-columns="filtersData?.displayedColumns"
    @apply="applyFilters"
  />
  <div v-if="isLoading" class="mt-28">
    <Loader :content="true" />
  </div>
  <div v-else-if="!isLoading && isEmpty(studentReceivableFees)">
    <NoRecordFound />
  </div>
  <TableWrapper v-else>
    <THead>
      <TRHead>
        <TH
          v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
          v-show="
            showColumnInDataTable(
              filtersData,
              head.dataTableName,
              RECEIVABLE_DATA.defaultShowHeaders,
            )
          "
          :key="head.id"
        >
          <span v-i18n="fees">
            {{ head.dataTableName }}
          </span>
        </TH>
        <TH v-permission="campusScopePermission">Class</TH>
        <TH v-permission="campusClassScopePermissions">Section</TH>
        <TH>Actions</TH>
      </TRHead>
    </THead>
    <TBody>
      <TRBody v-for="(fee, index) in studentReceivableFees" :key="`index-${fee.id}`">
        <TD>
          <div
            @click="
              ;[
                isAllowedRedirectToPayableByRole &&
                  redirectToStudentMode(fee.student, 'receivable', 'student-fee-receivable'),
              ]
            "
          >
            <SingleUserDisplay
              :required-items="1"
              :user="fee.student"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="
                dropdownTopBottom(index, studentReceivableFees, 'bottom-6', 'top-6')
              "
              :tooltip="
                dropdownTopBottom(index, studentReceivableFees, 'tooltip-bottom', 'tooltip-top')
              "
            />
          </div>
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Student Email')">
          {{ (fee.student && fee.student.email) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Student Phone')">
          <ReadOnlyPhoneNumberInDataTable :phone="fee.student.phone" />
        </TD>
        <TD>
          <div
            v-if="fee.student.student_info.guardians && fee.student.student_info.guardians.length"
          >
            <MultiUserDisplay
              :options="fee.student.student_info.guardians"
              label="full_name"
              :multiple-items-list-to-get-detail="guardiansDetailList"
              :image="true"
              :dropdown-top-bottom="
                dropdownTopBottom(index, studentReceivableFees, 'bottom-6', 'top-6')
              "
              :tooltip="
                dropdownTopBottom(index, studentReceivableFees, 'tooltip-bottom', 'tooltip-top')
              "
            />
          </div>
          <span v-else>
            {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </span>
        </TD>
        <TD>
          <span
            :class="{
              'cursor-pointer text-primary-purple-600': fee.section_fee || fee.title,
            }"
            @click="goToSectionFeeDetail(fee)"
          >
            {{
              fee.section_fee
                ? fee.section_fee.title
                : fee.title || GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </span>
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Fee Type')">
          {{ fee.fee_type.title }}
        </TD>
        <TD>
          <span class="cursor-pointer text-primary-purple-600" @click="goToDetailPage(fee)">
            {{ fee.fee_amount }}
          </span>
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Paid')">
          {{ fee.paid_amount }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Payable')">
          {{ fee.payable_amount }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Currency')">
          <span class="uppercase">
            {{ fee.currency }}
          </span>
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Discount')">
          {{ fee.discount_amount }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Fine')">
          {{ fee.fine_amount }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Fine Reason')">
          {{ fee.fine_reason }}
        </TD>
        <TD>
          {{ $filters.formattedDate(fee.inserted_at) }}
        </TD>
        <TD>
          {{ $filters.formattedDate(fee.due_date) }}
        </TD>
        <TD>
          <PaymentStatus class="block text-center w-16 max-w-1" :status="fee.status" />
        </TD>
        <TD v-permission="campusScopePermission">
          {{ fee.section.class.title }}
        </TD>
        <TD v-permission="campusClassScopePermissions">
          {{ fee.section.title }}
        </TD>
        <TD>
          <TableAction
            :current-user="fee"
            :idx="index"
            :action-list="actionList"
            @action="feeAction"
          />
        </TD>
      </TRBody>
    </TBody>
  </TableWrapper>
  <Pagination
    v-if="showPagination"
    v-show="!isLoading"
    :key="`pagination${forceRender}`"
    :record-limit="filteredRecordLimit"
    :max-range="paginationCounts(studentReceivableFeesCounts, filteredRecordLimit)"
    @filterRecord="filterRecords"
  />
  <ValidationObserver @submit="addSingleStudentFee">
    <!-- Add Single Student Fee -->
    <UiModalContainer
      v-if="showUiModal"
      footer-classes="flex justify-end gap-3"
      :modal-show="showUiModal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="fees">ADD_STUDENT_FEE</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiInput
            v-model="addFeeData.title"
            type="text"
            name="F_TITLE"
            title="F_TITLE"
            label="F_TITLE"
            placeholder="F_TITLE"
            :class="$style.widthItems"
            class="flex-1 w-full"
            rules="required"
          />
        </InputFieldWrapper>

        <InputFieldWrapper>
          <UiSingleSelect
            v-model="addFeeData.student_id"
            title="Student Name"
            :options="studentList"
            label="full_name"
            :class="$style.widthItems"
            class="flex-1"
            :image="true"
            reduce="id"
            rules="required"
            :search-through-api="true"
            @search="getStudents"
            @change="getUserSpecificSectionsList(addFeeData.student_id)"
            @deSelect="getUserSpecificSectionsList(addFeeData.student_id)"
          />
          <UiSingleSelect
            v-model="addFeeData.fee_type_id"
            title="FEE_TYPE"
            :options="feeTypes"
            label="title"
            :class="$style.widthItems"
            class="flex-1"
            reduce="id"
            rules="required"
            :search-through-api="true"
            @search="fetchFeeTypes"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiInput
            v-model="addFeeData.fee_amount"
            type="text"
            name="Amount"
            title="Amount"
            label="Amount"
            placeholder="Amount"
            :class="$style.widthItems"
            class="flex-1 w-full"
            rules="numeric|required"
          />
          <UiInput
            v-model="addFeeData.payment_method"
            type="text"
            name="Payment Method"
            title="Payment Method"
            label="Payment Method"
            placeholder="Payment Method"
            :class="$style.widthItems"
            class="flex-1 w-full"
            rules="alpha"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiDatePicker
            v-model="addFeeData.due_date"
            title="Due Date"
            :class="$style.widthItems"
            class="flex-1"
            :date-mode="DATE_MODE.END_DATE_TIME"
            :is-requried="true"
            rules="required"
          />
          <UiSingleSelect
            v-model="addFeeData.currency"
            :rules="'required'"
            :options="allowedCurrencies"
            title="CURRENCY_FOR_FEE"
            reduce="id"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper v-if="!currentSectionScope">
          <UiSingleSelect
            v-model="addFeeData.class_id"
            title="CLSS"
            :options="classList"
            label="title"
            :class="$style.widthItems"
            class="flex-1"
            reduce="id"
            :disabled="!!currentClassScope"
            rules="required"
            @change="getSectionsList(addFeeData.student_id, addFeeData.class_id)"
          />
          <UiSingleSelect
            v-model="addFeeData.section_id"
            title="Section"
            :options="sectionsList"
            label="title"
            :class="$style.widthItems"
            class="flex-1"
            reduce="id"
            rules="required"
          />
        </InputFieldWrapper>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              <span>Save</span>
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>

  <!-- Accept Payment modal -->
  <AcceptPayment
    v-if="acceptPaymentModal"
    :accept-payment-modal="acceptPaymentModal"
    :current-user="currentUser"
    :idx="currentUserPosition"
    @showAcceptPaymentModal="showAcceptPaymentModal"
    @updateResults="filterRecords"
  />
  <!-- Add Fine Modal -->
  <AddFine
    v-if="addFineModal"
    :add-fine-modal="addFineModal"
    :current-user="currentUser"
    :idx="currentUserPosition"
    @showAddFineModal="showAddFineModal"
    @toggle="filterRecords"
  />
  <!-- Add Discount Modal -->
  <AddDiscount
    v-if="addDiscountModal"
    :add-discount-modal="addDiscountModal"
    :current-user="currentUser"
    :idx="currentUserPosition"
    @showDiscountModal="showDiscountModal"
    @toggle="filterRecords"
  />
  <!-- Add Discount Modal -->
  <ChangeDueDateModal
    v-if="changeDueDate"
    :change-due-date="changeDueDate"
    :current-user="currentUser"
    :idx="currentUserPosition"
    @showDueDateModal="showDueDateModal"
  />
  <SendNotificationModal
    v-if="showSendNotificationModal"
    :show-model="showSendNotificationModal"
    :active-obj="currentUser"
    @handleClick="toogleSendNotificationModal"
    @update="sendNotificationToUser"
  />
  <PrintPaidInvoice v-if="feeInvoice" />
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import ReceivableFilter from './ReceivableFilter.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import TableAction from '@components/TableAction.vue'
import PaymentStatus from '@/src/router/views/finance/fees/feeActionsModals/PaymentStatus.vue'
import AcceptPayment from '@views/finance/fees/feeActionsModals/Accept-Payment.vue'
import AddFine from '@views/finance/fees/feeActionsModals/Add-Fine-Modal.vue'
import AddDiscount from '@views/finance/fees/feeActionsModals/Add-Discount-Modal.vue'
import ChangeDueDateModal from '@views/finance/fees/feeActionsModals/Change-Due-Date.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import SendNotificationModal from '@components/fee/SendNotification.vue'
import MultiUserDisplay from '@src/components/UiElements/MultiUserDisplay.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import PrintPaidInvoice from '@/src/components/fee/PrintPaidInvoice.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import ReadOnlyPhoneNumberInDataTable from '@src/components/UiElements/PhoneDisplay.vue'
import RECEIVABLE_DATA from '@src/router/views/finance/fees/receivable/receivable.json'
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  dropdownTopBottom,
  objectDeepCopy,
  showColumnInDataTable,
  findCurrencyById,
} from '@utils/generalUtil.js'
import GeneralUtil from '@src/mixins/general-mixins.js'
import { isEmpty } from 'lodash'
import { DATE_MODE } from '@/src/constants/date-time-constants.js'
import { URL_SCHEME } from '@src/services/.env'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants.js'
import { redirectToStudentMode } from '@src/utils/settings/tenant-user.util.js'
import fileMixin from '@src/mixins/file-mixins'
import scrollMixin from '@src/mixins/scroll-mixin'
import { rightBarStats } from '@/src/router/views/finance/fees/receivable/receivable.util.js'
import { buildWhereQuery } from '@/src/utils/filters'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    ReceivableFilter,
    UiSingleSelect,
    UiModalContainer,
    UiInput,
    UiDatePicker,
    TableAction,
    PaymentStatus,
    ValidationObserver,
    AcceptPayment,
    AddFine,
    AddDiscount,
    Pagination,
    Loader,
    ChangeDueDateModal,
    SendNotificationModal,
    NoRecordFound,
    ReadOnlyPhoneNumberInDataTable,
    MultiUserDisplay,
    SingleUserDisplay,
    PrintPaidInvoice,
    UIButton,
    InputFieldWrapper,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralUtil, scrollMixin, fileMixin],
  data() {
    return {
      isEmpty,
      DATE_MODE,
      GENERAL_CONSTANTS,
      RECEIVABLE_DATA,
      tableHeaders: RECEIVABLE_DATA.receivableHeaders,
      defaultSelectedColumns: RECEIVABLE_DATA.defaultSelectedColumns,
      actionList: RECEIVABLE_DATA.receivableActionsList,
      alwaysDisplayColumnsForCSV: {
        'student.first_name': 'First Name',
        'student.last_name': 'Last Name',
        'student.student_info.guardians.user': 'Guardians',
      },
      addFeeData: {
        student_id: '',
        fee_type_id: '',
        title: '',
        fee_amount: '',
        status: 'due',
        payment_method: '',
        due_date: '',
        section_id: '',
        currency: '',
        class_id: '',
      },
      isLoading: false,
      sectionsList: [],
      classList: [],
      forceRender: 0,
      currentUser: {},
      fees: 'fees',
      acceptPaymentModal: false,
      addFineModal: false,
      addDiscountModal: false,
      changeDueDate: false,
      currentUserPosition: '',
      filteredRecordLimit: 10,
      showPagination: false,
      studentList: [],
      filtersData: {},
      showSendNotificationModal: false,
      campusClassScopePermissions: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.CLASS_LEVEL],
      },
      campusScopePermission: {
        basedOn: [SCOPE_LEVELS.CAMPUS_LEVEL],
      },
    }
  },

  computed: {
    ...mapState({
      showUiModal: (state) => state.layout.showUiModal,
      studentReceivableFees: (state) => state.fee.studentReceivableFees?.records || [],
      classesList: (state) => state.classes.classesList,
      studentReceivableFeesCounts: (state) => state.fee.studentReceivableFees.meta?.total_records,
      feeTypes: (state) => state.fee.feeTypes?.records,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      activeRole: (state) => state.layout.activeRole,
      feeInvoice: (state) => state.fee.feeInvoice,
      defaultCurrencyForFee: (state) =>
        state.settings?.siteSetting?.default_currency?.default_currency,
    }),
    ...mapGetters('layout', ['allowedCurrencies']),

    currencySymbol() {
      return findCurrencyById()?.symbol
    },
    guardiansDetailList() {
      let detailList = []
      if (showColumnInDataTable(this.filtersData, 'Guardian Email')) {
        detailList.push('email')
      }
      if (showColumnInDataTable(this.filtersData, 'Guardian Phone')) {
        detailList.push('phone')
      }
      return detailList
    },
    isAllowedRedirectToPayableByRole() {
      let rolesList = ['section_student', 'gaurdian']
      return !rolesList.includes(this.activeRole)
    },
    isStudentOrGuardian() {
      return [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(this.activeRole)
    },
  },

  watch: {
    showUiModal: {
      deep: true,
      handler(value) {
        if (value) {
          this.fetchFeeTypes()
          this.getStudents()
        }
      },
    },
    currentSectionScope: {
      handler() {
        this.filterRecords()
      },
    },
    currentClassScope: {
      handler() {
        this.filterRecords()
      },
    },
    currentCampusScope: {
      handler(val) {
        if (val) this.filterRecords()
      },
    },
    classesList: {
      handler() {
        if (this.currentClassScope) this.classList = objectDeepCopy(this.classesList)
      },
    },
  },
  /**
   * Mounted hook
   * @description Calling FilterRecord method
   */
  mounted() {
    this.filterRecords()
  },
  /**
   * Created hook
   * @description Setting static right bar data
   */
  created() {
    this.setRightBar()
    this.initData()
    if (this.currentClassScope) {
      this.classList = objectDeepCopy(this.classesList)
      this.addFeeData.class_id = this.currentClassScope.id
    }
  },
  methods: {
    dropdownTopBottom,
    redirectToStudentMode,
    showColumnInDataTable,
    initData() {
      this.addFeeData.currency = this.defaultCurrencyForFee
    },
    toogleSendNotificationModal() {
      this.showSendNotificationModal = !this.showSendNotificationModal
    },
    fetchFeeTypes(query = '') {
      let queryPayload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query) } }
      this.getFeeTypes(queryPayload)
    },
    getUserSpecificSectionsList(id) {
      this.addFeeData.section_id = ''
      this.sectionsList = []
      if (!this.currentClassScope) {
        this.addFeeData.class_id = ''
        this.classList = []
        this.studentEnrolledClasses(id).then((res) => {
          this.classList = res.records
        })
      } else {
        if (id) this.getSectionsList(id, this.addFeeData.class_id)
      }
    },
    getSectionsList(studentId, classId) {
      this.studentEnrolledSections({ student_id: studentId, class_id: classId }).then((res) => {
        this.sectionsList = res.records
      })
    },
    getStudents(query = '') {
      let queryPayload = { $where: { ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query) } }
      this.getStudentsWithFilters(queryPayload).then((res) => {
        this.studentList = res.records
      })
    },
    async _payStudentFees(fees) {
      const basePath = location.host
      const routePath = this.$route.fullPath
      const data = {
        student_fee_id: fees.id,
        success_url: `${URL_SCHEME}://${basePath}${routePath}`,
        failuer_url: `${URL_SCHEME}://${basePath}${routePath}`,
      }
      const [res, err] = await this.payStudentFees(data)
      if (res) window.location.href = res.data.url
    },

    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
      this.forceRender++
    },
    /**
     * Show Accept Payment Modal
     * @description Negates the value of accept-payment modal
     */
    showAcceptPaymentModal() {
      this.acceptPaymentModal = !this.acceptPaymentModal
    },
    /**
     * Show Add Fine Modal
     * @description Negates the value of add-fine modal
     */
    showAddFineModal() {
      this.addFineModal = !this.addFineModal
    },
    goToDetailPage(fee) {
      localStorage.setItem('redirectTo', this.isStudentOrGuardian ? 'payable' : 'receivable')
      this.$router?.push({ name: 'fee-details', params: { id: fee.id } })
    },
    goToSectionFeeDetail(fee) {
      if (!fee.section_fee && !fee.title) return

      localStorage.setItem('redirectTo', this.isStudentOrGuardian ? 'payable' : 'receivable')

      if (fee.section_fee) {
        this.$router?.push({ name: 'section-fee-details', params: { id: fee.section_fee.id } })
      } else this.$router?.push({ name: 'fee-details', params: { id: fee.id } })
    },
    /**
     * Show Discount Modal
     * @description Negates the value of add-discount modal
     */
    showDiscountModal() {
      this.addDiscountModal = !this.addDiscountModal
    },
    /**
     * Show Due Date Modal
     * @description Negates the value of change-due-date modal
     */
    showDueDateModal(payload) {
      this.changeDueDate = !this.changeDueDate
      if (payload) this.filterRecords()
    },

    /**
     * Add Single Student Fee
     * @param {boolean} invalid
     * @returns {void}
     * @description Checking if student data is valid then adding student fee
     */
    addSingleStudentFee() {
      this.isLoading = true
      this.addSingleStudentFees(this.addFeeData)
        .then(() => {
          this.setShowUiModal(false)
          this.resetSingleFeeData()
          this.isLoading = false
          this.filterRecords()
          this.forceRender++
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    /**
     * Fee Action
     * @param {String} action
     * @param {object} currentStudent
     * @param {number} idx - Index of current student
     * @returns {void}
     * @description Show the modal on basis of action
     */
    feeAction(action, currentStudent, idx) {
      this.currentUser = currentStudent
      this.currentUserPosition = idx
      switch (action) {
        case 'Accept Payment':
          this.showAcceptPaymentModal()
          break
        case 'Add Fine':
          this.showAddFineModal()
          break
        case 'Add Discount':
          this.showDiscountModal()
          break
        case 'Send Notification':
          this.toogleSendNotificationModal()
          break
        case 'Change Fee Due Date':
          this.showDueDateModal()
          break
        case 'PAY_ONLINE':
          this._payStudentFees(currentStudent)
          break
      }
    },
    /**
     * Reset Single Fee Data
     * @description Reset the fee data to empty value
     */
    resetSingleFeeData() {
      this.addFeeData.student_id = ''
      this.addFeeData.fee_type_id = ''
      this.addFeeData.fee_amount = ''
      this.addFeeData.due_date = ''
      this.addFeeData.payment_method = ''
      this.addFeeData.fee_type_id = ''
      this.addFeeData.section_id = ''
      this.addFeeData.title = ''

      this.sectionsList = []
      this.addFeeData.currency = this.defaultCurrencyForFee
      if (!this.currentClassScope) {
        this.classList = []
        this.addFeeData.class_id = ''
      }
    },
    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal and setShowUiModal method on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.setShowModal(true)
          this.setShowUiModal(true)
          break
        case 'close':
          this.setShowModal(false)
          this.setShowUiModal(false)
          this.resetSingleFeeData()
          break
      }
    },
    /**
     * Filter Record
     * @param {number} range
     * @returns {void}
     * @description Method description
     * -  Filter the record on basis of range
     * -  Setting right bar data
     */
    filterRecords(range) {
      if (!this.currentCampusScope) return
      this.isLoading = true
      let payload = {
        skip: range?.skip || 0,
        limit: range?.limit || 10,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      if (this.isStudentOrGuardian)
        this.getStudentsPayableFees(payload)
          .then(() => {
            this.isLoading = false
            this.showPagination = this.studentReceivableFeesCounts > 10
            if (this.$route?.name === '/receivable') {
              this.getFeeReceivableStats(payload).then((response) => {
                let stats = response.data
                this.setRightBar(stats)
              })
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      else
        this.getStudentsReceivableFees(payload)
          .then(() => {
            this.showPagination = this.studentReceivableFeesCounts > 10
            if (this.$route?.name === 'receivable')
              this.getFeeReceivableStats(payload).then((response) => {
                let stats = response.data
                this.setRightBar(stats)
              })
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    setRightBar(stats) {
      const roles = [
        TENANT_ROLES.SUPER_ADMIN,
        TENANT_ROLES.SECTION_TEACHER,
        TENANT_ROLES.CAMPUS_ADMIN,
      ]
      const rightBarContent = {
        header: {
          title: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN].includes(this.activeRole)
            ? 'PAYABLE'
            : 'RECEIVABLE_FEE',
          buttons: [
            {
              title: 'DOWNLOAD_CSV',
              icon: 'download',
              iconHeight: '18',
              iconWidth: '18',
              classes: ['primary-button-right'],
              action: {
                func: () =>
                  this.downloadCSV('receivableList', { $where: this.filtersData?.$where }),
              },
              permissions: {
                basedOn: [SCOPE_LEVELS.SECTION_LEVEL],
              },
            },
          ],
        },
        stats: rightBarStats(
          stats,
          this.currencySymbol,
          this.currentCampusScope,
          this.currentSectionScope,
        ),
      }
      if (roles.includes(this.activeRole))
        rightBarContent.header.buttons.unshift({
          title: 'Add Student Fee',
          classes: ['primary-button-right'],
          action: { name: 'layout/setShowUiModal', payload: {} },
        })
      this.setRightbarContent(rightBarContent)
    },

    /**
     * Send Notification to User
     * @param {object} currentUser
     * @returns {void}
     * @description Sending notification to user
     */
    sendNotificationToUser(role) {
      const payload = {
        student_fee_id: this.currentUser.id,
        receiver_role_id: role.value,
        ...role,
      }
      this.sendFeeNotification(payload).then((res) => {
        this.toogleSendNotificationModal()
      })
    },

    ...mapActions('layout', ['setRightbarContent', 'setShowModal', 'setShowUiModal']),
    ...mapActions('fee', [
      'getStudentsReceivableFees',
      'getStudentsPayableFees',
      'getFeeTypes',
      'sendFeeNotification',
      'addSingleStudentFees',
      'getFeeReceivableStats',
    ]),
    ...mapActions('student', [
      'getStudentsWithFilters',
      'studentEnrolledClasses',
      'studentEnrolledSections',
    ]),
    ...mapActions('paymentMethods', ['payStudentFees']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
