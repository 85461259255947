<template>
  <ValidationObserver @submit="update">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="showModel"
      :modal-width="40"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Send Notification</span>
      </template>
      <template v-slot>
        <div>
          <div>
            <div class="mt-5 flex font-roboto text-base mb-4">
              <div class="w-40">Student Name:</div>
              <div class="text-menu capitalize">
                {{ activeObj.student.first_name }} {{ activeObj.student.last_name }}
              </div>
            </div>
            <div v-if="currentSectionScope" class="mt-5 flex font-roboto text-base mb-4">
              <div class="w-40">Class:</div>
              <div class="text-menu capitalize">
                {{ currentSectionScope.class.title }}
              </div>
            </div>
            <div v-if="currentSectionScope" class="mt-5 flex font-roboto text-base mb-4">
              <div class="w-40">Section:</div>
              <div class="text-menu capitalize">
                {{ currentSectionScope.title }}
              </div>
            </div>
          </div>
          <div class="border-b border-primary-grey w-full"></div>
          <div v-if="!isLoadingDetail">
            <div class="mt-5 flex">
              <div class="mr-1">{{ feeNotificationDetail.title }}</div>
            </div>
            <div class="md:flex gap-2">
              <div class="md:w-1/2 w-full">
                <p class="text-primary-grey-dark mt-4 mb-3 text-lg">Send a notification to:</p>
                <UiSingleSelect
                  v-model="role"
                  title="SELECT_USER"
                  :hide-title="true"
                  rules="required"
                  :label="'name'"
                  :options="rolesList"
                  class="text-text-color font-roboto text-sm font-normal flex-1"
                />
              </div>
              <div v-if="role && role.value === 'any_guardian'" class="md:w-1/2 w-full">
                <p class="text-primary-grey-dark mt-4 mb-3 text-lg">User:</p>
                <UiSingleSelect
                  v-model="receiverObj"
                  title="SELECT_USER"
                  :hide-title="true"
                  rules="required"
                  label="full_name"
                  :options="gaurdianList"
                  class="text-text-color font-roboto text-sm font-normal flex-1"
                />
              </div>
            </div>
            <div>
              <p class="text-primary-grey-dark mb-3 text-lg">Massage:</p>
              <InputBox
                :value="feeNotificationDetail.body"
                type="text"
                placeholder="write"
                class="flex-1"
                :disabled="true"
              />
            </div>
            <div class="mb-12">
              <p class="text-primary-grey-dark mb-3 text-lg">
                Please select the medium to send the notification
              </p>
              <div class="flex flex-wrap justify-between">
                <UiCheckBox
                  :model-value="selectedMedium === 'web_push'"
                  :circle-shape="true"
                  label="IN_APP_NOTIFICATION"
                  type="radio"
                  name="notification"
                  rules="required"
                  value="web_push"
                  @change="updateRadioButtonValue"
                />
                <UiCheckBox
                  :model-value="selectedMedium === 'sms'"
                  :circle-shape="true"
                  label="SMS"
                  type="radio"
                  name="notification"
                  rules="required"
                  value="sms"
                  @change="updateRadioButtonValue"
                />
                <UiCheckBox
                  :model-value="selectedMedium === 'email'"
                  :circle-shape="true"
                  label="EMAIL"
                  type="radio"
                  name="notification"
                  rules="required"
                  value="email"
                  @change="updateRadioButtonValue"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <Loader class="pb-40 pt-10" :content="true" />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary">
            <span v-if="modalButtonLoading">
              <Loader />
            </span>
            <span v-else>Send</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiCheckBox from '@components/UiElements/UiCheckbox.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { Form as ValidationObserver } from 'vee-validate'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'DeleteExamComponent',
  components: {
    UiModalContainer,
    Loader,
    ValidationObserver,
    UiSingleSelect,
    InputBox,
    UiCheckBox,
    UIButton,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },

    modalButtonLoading: {
      type: Boolean,
      default: false,
    },

    activeObj: { type: Object, default: () => ({}) },
  },
  emits: ['handleClick', 'update'],
  data() {
    return {
      role: null,
      selectedMedium: '',
      feeNotificationDetail: {},
      rolesList: [
        {
          id: 0,
          name: 'Student Only',
          value: 'student_only',
          receiverRoleId: 'section_student',
        },
        {
          id: 1,
          name: 'Associated Guardians',
          value: 'associated_guardians',
          receiverRoleId: 'guardian',
        },
        {
          id: 2,
          name: 'Main Guardian',
          value: 'main_guardian',
          receiverRoleId: 'guardian',
        },
        {
          id: 3,
          name: 'Any Guardian',
          value: 'any_guardian',
          receiverRoleId: 'guardian',
        },
      ],
      receiverObj: null,
      gaurdianList: [],
      isLoadingDetail: false,
    }
  },
  computed: {
    ...mapState('layout', ['currentSectionScope']),
  },
  created() {
    this._getFeeDetailForSendingNotification()
    this._getSingleStudentGaurdians()
  },
  methods: {
    handleClick() {
      this.$emit('handleClick')
    },
    update() {
      if (!this.selectedMedium) {
        this.$store.commit(
          'toast/NEW',
          { type: 'error', message: 'Please Select Medium First' },
          { root: true },
        )
        return
      }
      const payload = {
        ...this.role,
        ...this.feeNotificationDetail,
        receiver_id: this.receiverObj ? this.receiverObj.guardian_id : '',
        medium: this.selectedMedium,
      }
      this.$emit('update', payload)
    },
    async _getFeeDetailForSendingNotification() {
      this.isLoadingDetail = true
      const payload = { student_fee_id: this.activeObj.id, receiver_role_id: 'section_student' }
      const [res, err] = await this.getFeeDetailForSendingNotification(payload)
      this.isLoadingDetail = false
      this.feeNotificationDetail = res.data
    },
    async _getSingleStudentGaurdians() {
      const payload = { id: this.activeObj.student.id }
      const [res, err] = await this.getSingleStudentGaurdians(payload)
      if (!err) {
        this.gaurdianList = res.data.records.map((guardian) => {
          guardian.full_name = `${guardian.first_name} ${guardian.last_name}`
          return guardian
        })
      }
    },
    updateRadioButtonValue(radioBtnValue) {
      this.selectedMedium = radioBtnValue.value
    },

    ...mapActions('fee', ['getFeeDetailForSendingNotification']),

    ...mapActions('student', ['getSingleStudentGaurdians']),
  },
}
</script>
