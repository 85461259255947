import RECEIVABLE_DATA from '@/src/router/views/finance/fees/receivable/receivable.json'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (
  statsCount,
  currencySymbol,
  currentCampusScope,
  currentSectionScope,
) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: RECEIVABLE_DATA.PENDING_FEE_STUDENTS,
          value: statsCount?.pending_fee_students_count || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: RECEIVABLE_DATA.TOOLTIP_TOTAL_STUDENT,
        },
        {
          text: RECEIVABLE_DATA.TOTAL_PENDING_FEE,
          value: statsCount?.total_pending_fee_amount || GENERAL_CONSTANTS.EMPTY_RECORD,
          currency: currencySymbol,
          tooltipDescription: RECEIVABLE_DATA.TOOLTIP_TOTAL_PENDING_FEE,
        },
      ],
    },
  ]
  return stats
}
